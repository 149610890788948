// @flow

// Libraries
import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

// Components
import {
  ArgumentAxis,
  Chart,
  CommonSeriesSettings,
  Legend,
  Margin,
  MinorTickInterval,
  Point,
  Series,
  Tooltip,
  ValueAxis
} from 'devextreme-react/chart';
import RangeSelector, {
  Behavior,
  Scale,
  Size,
  SliderMarker
} from 'devextreme-react/range-selector';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { loadMessages, locale } from 'devextreme/localization';
import { Button } from '@material-ui/core';

// Styles
import { divideGraphsValue } from '../../../../../utils/methods';
import { CHART_VARIANTS } from '../../constants';
import { formatArgumentLabel } from '../../helpers';
import styles from './styles.sass';

const SHOW_MODES = {
  fromPeriod: 'fromPeriod',
  fromStartYear: 'fromStartYear',
  months: 'months',
  weeks: 'weeks',
  days: 'days'
};

const IGNORE_FIELDS = ['label', 'date'];

const sumRowsInChunk = chunk =>
  chunk.reduce(
    (sum, row) => ({
      ...Object.fromEntries(
        Object.entries(row).map(([key, val]) => [
          key,
          IGNORE_FIELDS.includes(key)
            ? val
            : sum[key]
            ? // check on empty value
              sum[key] + val
            : val
        ])
      )
    }),
    Object.keys(chunk[0]).reduce((obj, key) => ({ ...obj, [key]: 0 }), {})
  );

const sumValuesInChunks = (chunks, variant) => {
  const getValueByVariant = (val, arr) => {
    switch (variant) {
      case CHART_VARIANTS.average: {
        return val / arr.length;
      }

      case CHART_VARIANTS.total: {
        return val;
      }

      default: {
        return val;
      }
    }
  };

  return chunks.reduce((summaryChunks, arr) => {
    const sumRows = sumRowsInChunk(arr);
    const formattedValues =
      arr.length > 1
        ? Object.fromEntries(
            Object.entries(sumRows).map(([key, val]) => [
              key,
              IGNORE_FIELDS.includes(key) ? val : getValueByVariant(val, arr)
            ])
          )
        : sumRows;

    return [...summaryChunks, formattedValues];
  }, []);
};

const DATE_FORMAT = 'DD.MM.YYYY';
const DATE_FORMAT_DEFAULT = 'YYYY/MM/DD';

const RowChartWithZoom = ({
  dataSource,
  series,
  tooltipText,
  range: period,
  variant
}: any) => {
  const START_VALUE = dataSource[0].date;
  const END_VALUE = dataSource[dataSource.length - 1].date;
  const VALUES_OF_SHOW_MODES = {
    [SHOW_MODES.fromPeriod]: {
      startValue: period.startValue,
      endValue: moment(period.endValue, DATE_FORMAT).format(DATE_FORMAT)
    },
    [SHOW_MODES.fromStartYear]: {
      startValue: moment(END_VALUE, DATE_FORMAT)
        .startOf('year')
        .format(DATE_FORMAT),
      endValue: END_VALUE
    },
    [SHOW_MODES.weeks]: {
      startValue: moment(END_VALUE, DATE_FORMAT)
        .subtract(3, 'month')
        .startOf('isoWeek')
        .format(DATE_FORMAT),
      endValue: END_VALUE
    }
  };

  const [range, setRange] = React.useState({
    startValue: START_VALUE,
    endValue: END_VALUE
  });

  const [showMode, setShowMode] = React.useState(SHOW_MODES.months);

  loadMessages(ruMessages);
  locale(navigator.language);

  // https://app.weeek.net/ws/435314/task/8210
  // const getShowMode = countOfDaysInSelectedRange => {
  //   if (
  //     range.startValue ===
  //       VALUES_OF_SHOW_MODES[SHOW_MODES.fromPeriod].startValue &&
  //     range.endValue === VALUES_OF_SHOW_MODES[SHOW_MODES.fromPeriod].endValue
  //   ) {
  //     return SHOW_MODES.fromPeriod;
  //   }
  //
  //   if (
  //     range.startValue ===
  //       VALUES_OF_SHOW_MODES[SHOW_MODES.fromStartYear].startValue &&
  //     range.endValue === VALUES_OF_SHOW_MODES[SHOW_MODES.fromStartYear].endValue
  //   ) {
  //     return SHOW_MODES.fromStartYear;
  //   }
  //
  //   if (countOfDaysInSelectedRange <= 31) {
  //     return SHOW_MODES.days;
  //   }
  //
  //   if (countOfDaysInSelectedRange <= 183) {
  //     return SHOW_MODES.weeks;
  //   }
  //
  //   return SHOW_MODES.months;
  // };

  const handleChangeRange = (startValue, endValue) => {
    setRange({ startValue, endValue });
  };

  const countOfDaysInSelectedRange = moment(range.endValue, DATE_FORMAT).diff(
    moment(range.startValue, DATE_FORMAT),
    'days'
  );

  // https://app.weeek.net/ws/435314/task/8210
  // React.useEffect(() => {
  //   setShowMode(getShowMode(countOfDaysInSelectedRange));
  // }, [range]);

  const chartData = React.useMemo(() => {
    const filteredData = dataSource.filter(({ date }) =>
      moment(date, DATE_FORMAT).isBetween(
        moment(range.startValue, DATE_FORMAT).subtract(1, 'day'),
        moment(range.endValue, DATE_FORMAT).add(1, 'day')
      )
    );

    if (showMode === SHOW_MODES.days || countOfDaysInSelectedRange < 30) {
      return filteredData.map(item => {
        const label = moment(item.date, 'DD.MM.YYYY').format('DD MMM');

        return {
          ...item,
          label: `${label.split(' ')[0]} ${label.split(' ')[1].substring(0, 3)}`
        };
      });
    }

    const getChunks = arr => {
      const chunks = [];

      // eslint-disable-next-line for-direction
      let i = arr.length - 1;

      while (i > 0) {
        const curRangeDate = moment(arr[i].date, DATE_FORMAT);
        const countOfDaysBetweenRange = curRangeDate.diff(
          curRangeDate
            .clone()
            .startOf(countOfDaysInSelectedRange >= 184 ? 'months' : 'weeks'),
          'days'
        );

        const range = {
          startOf:
            i - countOfDaysBetweenRange >= 0 ? i - countOfDaysBetweenRange : 0,
          endOf: i + 1
        };

        const getLabel = date =>
          countOfDaysBetweenRange >= 27
            ? `${moment(date, DATE_FORMAT).format('DD.MM.YYYY') + '_month'}`
            : `${moment(date, DATE_FORMAT)
                .subtract(range.endOf - range.startOf - 1, 'day')
                .format('DD.MM')} - ${moment(date, DATE_FORMAT).format(
                'DD.MM'
              )}`;

        chunks.push(
          arr.slice(range.startOf, range.endOf).map(item => ({
            ...item,
            label: getLabel(item.date)
          }))
        );

        i = range.startOf - 1;
      }

      return chunks.reverse().filter(item => item.length > 1);
    };

    const chunks = getChunks(filteredData, variant);
    return sumValuesInChunks(chunks);
  }, [range, showMode]);

  const rangeData = React.useMemo(() => {
    return dataSource.map(day => ({
      ...day,
      date: moment(day.date, DATE_FORMAT).format(DATE_FORMAT_DEFAULT)
    }));
  }, [dataSource]);

  const onLegendClick = e =>
    e.target.isVisible() ? e.target.hide() : e.target.show();

  const renderScale = () => {
    if (showMode === 'months') {
      return (
        <Scale
          valueType="datetime"
          startValue={moment(START_VALUE, DATE_FORMAT).format(
            DATE_FORMAT_DEFAULT
          )}
          endValue={moment(END_VALUE, DATE_FORMAT).format(DATE_FORMAT_DEFAULT)}
          minorTickInterval="month"
          tickInterval="month"
        >
          <MinorTickInterval days={1} />
        </Scale>
      );
    }

    if (showMode === 'weeks') {
      return (
        <Scale
          valueType="datetime"
          startValue={moment(START_VALUE, DATE_FORMAT).format(
            DATE_FORMAT_DEFAULT
          )}
          endValue={moment(END_VALUE, DATE_FORMAT).format(DATE_FORMAT_DEFAULT)}
          minorTickInterval="week"
          tickInterval="week"
        >
          <MinorTickInterval days={1} />
        </Scale>
      );
    }

    return (
      <Scale
        valueType="datetime"
        startValue={moment(START_VALUE, DATE_FORMAT).format(
          DATE_FORMAT_DEFAULT
        )}
        endValue={moment(END_VALUE, DATE_FORMAT).format(DATE_FORMAT_DEFAULT)}
        minorTickInterval="day"
        tickInterval="day"
      >
        <MinorTickInterval days={1} />
      </Scale>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.Actions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowMode('fromPeriod');
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.fromPeriod && styles.Action_selected
            )
          }}
        >
          За период
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowMode('fromStartYear');
            handleChangeRange(
              VALUES_OF_SHOW_MODES[SHOW_MODES.fromStartYear].startValue,
              moment(range.endValue, DATE_FORMAT).format(DATE_FORMAT)
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.fromStartYear && styles.Action_selected
            )
          }}
        >
          С начала года
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowMode('months');
            handleChangeRange(
              moment(range.startValue, DATE_FORMAT)
                .startOf('month')
                .format(DATE_FORMAT),
              moment(range.endValue, DATE_FORMAT)
                .endOf('month')
                .format(DATE_FORMAT)
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.months && styles.Action_selected
            )
          }}
        >
          Месяцы
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowMode('weeks');
            handleChangeRange(
              moment(range.startValue, DATE_FORMAT)
                .startOf('isoWeek')
                .format(DATE_FORMAT),
              moment(range.endValue, DATE_FORMAT)
                .endOf('isoWeek')
                .format(DATE_FORMAT)
            );
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.weeks && styles.Action_selected
            )
          }}
        >
          Недели
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setShowMode('days');
          }}
          classes={{
            root: classnames(
              styles.Action,
              showMode === SHOW_MODES.days && styles.Action_selected
            )
          }}
        >
          Дни
        </Button>
      </div>
      <Chart
        palette={series.map(({ color }) => color)}
        dataSource={chartData}
        onLegendClick={onLegendClick}
      >
        {series.map(({ key, name }) => (
          <Series
            key={name}
            name={name}
            argumentField="label"
            valueField={key}
          />
        ))}
        <ArgumentAxis
          label={{ customizeText: formatArgumentLabel }}
          visualRange={range}
        />
        <ValueAxis
          label={{
            customizeText: ({ value }) => divideGraphsValue(value)
          }}
        />
        <CommonSeriesSettings>
          <Point size={7} />
        </CommonSeriesSettings>
        <Legend verticalAlignment="top" horizontalAlignment="right" />
        <Tooltip
          enabled
          shared
          customizeTooltip={pointInfo =>
            customizeTooltip({ pointInfo, tooltipText, showMode })
          }
        />
      </Chart>
      <RangeSelector
        dataSource={rangeData}
        value={{
          startValue: moment(range.startValue, DATE_FORMAT).format(
            DATE_FORMAT_DEFAULT
          ),
          endValue: moment(range.endValue, DATE_FORMAT).format(
            DATE_FORMAT_DEFAULT
          )
        }}
        onValueChanged={props => {
          const { value } = props;
          const [from, to] = value;
          if (
            moment(from).format(DATE_FORMAT_DEFAULT) !==
              moment(range.startValue, DATE_FORMAT).format(
                DATE_FORMAT_DEFAULT
              ) ||
            moment(to).format(DATE_FORMAT_DEFAULT) !==
              moment(range.endValue, DATE_FORMAT).format(DATE_FORMAT_DEFAULT)
          ) {
            if (showMode === 'months') {
              const isFirstDayOfMonth = moment(to).isSame(
                moment(to).startOf('month')
              );

              handleChangeRange(
                moment(from).format(DATE_FORMAT),
                isFirstDayOfMonth
                  ? moment(to)
                      .startOf('month')
                      .subtract(1, 'day')
                      .format(DATE_FORMAT)
                  : moment(to).format(DATE_FORMAT)
              );
            } else if (showMode === 'weeks') {
              const diffInDays = moment(to).diff(moment(from), 'days');

              handleChangeRange(
                moment(from).format(DATE_FORMAT),
                diffInDays > 7
                  ? moment(to)
                      .subtract(1, 'day')
                      .endOf('isoWeek')
                      .format(DATE_FORMAT)
                  : moment(to)
                      .startOf('isoWeek')
                      .subtract(1, 'day')
                      .format(DATE_FORMAT)
              );
            } else {
              handleChangeRange(
                moment(from).format(DATE_FORMAT),
                moment(to).format(DATE_FORMAT)
              );
            }
          }
        }}
      >
        <Size height={120} />
        <Margin left={40} right={80} />
        {renderScale()}
        <SliderMarker format="dd MMMM" />
      </RangeSelector>
    </React.Fragment>
  );
};

const customizeTooltip = ({
  pointInfo,
  tooltipText = { days: 'Значения за', average: 'Средние значения за' },
  showMode
}) => {
  const { seriesName, value, points } = pointInfo;
  const info = `<div>${seriesName}: ${divideGraphsValue(value)}</div>`;
  const label = points[0].point.data.label.replace('_month', '');
  const averageModes = [SHOW_MODES.months, SHOW_MODES.weeks];
  // если режим просмотра - месяцы и заголовок не содержит цифры, то переводим в формат месяца, иначе - дня
  const formattedLabel =
    averageModes.includes(showMode) && !/\d/.test(label)
      ? moment(label, 'MMM').format('MMMM')
      : label.includes('-') // если это диапазон дат
      ? label
      : moment(label, 'DD.MM.YYYY').format('MMMM');
  const { days: daysText } = tooltipText;

  const text = showMode === SHOW_MODES.months ? '' : daysText;

  return {
    html: `
      <div>
        ${text} ${
      showMode === SHOW_MODES.months
        ? formattedLabel.slice(0, 1).toUpperCase() +
          formattedLabel.slice(1, formattedLabel.length)
        : formattedLabel
    }
      </div>
      ${info}
    `
  };
};

export default RowChartWithZoom;
